import { useState } from "react";
import {
  font,
  lineHeight,
  viewport,
} from "../../../styles/cssinjs/helpers/variables.js";
export default function PromoBanner() {
  const [isOpen, setIsOpen] = useState(true);
  const Element = () => {
    return (
      <div className="container promo-22-banner fx fx--cv" id="promo-div">
        <div
          className="content-div fx fx--tb"
          onClick={() => (window.location.href = "/how-to-grow-your-brand")}
        >
          <div className="md:d-none pr-2">
            <svg width="24" height="24">
              <use href="/sprite.svg#promo-tree"></use>
            </svg>
          </div>
          <p className="details">
            <strong>Grow your brand,</strong> not a marketplace.{" "}
            <span className="mid-text">
              Highlight what makes your business special.
            </span>{" "}
            <a href="/how-to-grow-your-brand">Watch the video</a>
          </p>
        </div>
        <span className="close-element" onClick={() => setIsOpen(false)}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.613 4.21a1 1 0 0 0-1.32 1.497l6.291 6.291-6.291 6.292-.083.094a1 1 0 0 0 1.497 1.32l6.291-6.291 6.292 6.291.094.083a1 1 0 0 0 1.32-1.497l-6.291-6.292 6.291-6.29.083-.095a1 1 0 0 0-1.497-1.32l-6.292 6.291-6.29-6.291-.095-.083z"
              fill="#05857c"
              opacity=".5"
            ></path>
          </svg>
        </span>
        <style jsx>
          {`
            @media (max-width: ${viewport.desktop.sm}) {
              .mid-text {
                display: none;
              }
            }
          `}
        </style>
      </div>
    );
  };
  return isOpen ? <Element /> : null;
}
