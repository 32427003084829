import Link from "next/link";
import { useCarousel } from "../../../util/carouselHooks";
import { easeInOutCubic } from "../../../util/utils";

const CTAWithIntegrations = ({ data }) => {
  let items = data.cards; // Your array
  let cards = [
    { ...items[items.length - 2], dup: true },
    { ...items[items.length - 1], dup: true },
    ...items.map((item) => ({ ...item, dup: false })),
    { ...items[0], dup: true },
    { ...items[1], dup: true },
    { ...items[2], dup: true },
  ]; // Include duplicates

  const carousalOptions = {
    arrayWithoutDups: items,
    numberOfDupsInStart: 2,
    autoPlayDuration: 2000,
    transitionDuration: 1000,
    transitionFunction: easeInOutCubic,
  };

  const { ulRef, liRefs, handlers } = useCarousel(carousalOptions);
  const { savePreviousIndex, setPreviousIndex } = handlers;

  const addToRefs = (el) => {
    if (el && !liRefs.current.includes(el)) {
      liRefs.current.push(el);
    }
  };

  return (
    <section className="home-integrations br-8 bg-light-blue-grey lg:mb-20 md:mb-10 py-10  mx-auto">
      <article className="text-center">
        <h2 className="text-lg lg:mb-3 md:mb-2">{data.title}</h2>
        <div className="mx-auto">
          <p className="text-sm">
            {data.desc}{" "}
            <Link
              href={data.link.href}
              className="text-brandcolor text-sm"
              id="all-integrations-fourth-fold"
            >
              {data.link.text}
            </Link>
          </p>
        </div>
        <ul
          className="carousal-container"
          ref={ulRef}
          onMouseEnter={savePreviousIndex}
          onMouseLeave={setPreviousIndex}
        >
          {cards.map((card, index) => {
            return (
              <CarouselCard
                key={index}
                card={card}
                index={index}
                refs={{ addToRefs }}
                handlers={handlers}
              />
            );
          })}
        </ul>
      </article>
    </section>
  );
};

const CarouselCard = ({ card, index, refs, handlers }) => {
  const {
    handleMouseEnter,
    handleMouseLeave,
    handleTouchStart,
    handleTouchEnd,
  } = handlers;

  return (
    <li
      key={card.id}
      className={`carousal-item`}
      id={card.id}
      ref={refs.addToRefs}
      onMouseEnter={() => handleMouseEnter(index)}
      onMouseLeave={handleMouseLeave}
      onTouchStart={() => handleTouchStart(index)}
      onTouchEnd={handleTouchEnd}
      aria-hidden={card.dup ? "true" : "false"}
    >
      <Link href={card.link} className="carousal-item-link">
        <img
          src={card.src}
          alt={card.alt}
          className="carousal-item-img"
          aria-hidden="true"
        />
        <div className="carousal-item-content lg:ml-3">
          <h3 className="fw-600">{card.title}</h3>
          <p>{card.desc}</p>
        </div>
      </Link>
    </li>
  );
};

export default CTAWithIntegrations;
