function generateSchema(data) {
  const schema = {
    "@context": "http://schema.org",
    "@type": "FAQPage",
    mainEntity: [],
  };

  data.forEach((item) => {
    const schemaItem = {
      "@type": "Question",
      name: item.ques || item.title,
      acceptedAnswer: {
        "@type": "Answer",
        text: item.ans
          ? Array.isArray(item.ans)
            ? item.ans.join(" ")
            : item.ans
          : Array.isArray(item.desc)
          ? item.desc.join(" ")
          : item.desc,
      },
    };
    schema["mainEntity"].push(schemaItem);
  });

  return schema;
}

export default function Schema({ data }) {
  const schema = generateSchema(data);

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
    />
  );
}
